/* 
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Syne:wght@800&display=swap");

.full {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.fbody {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #7d7d7d;
}

.box {
  position: relative;
  width: 650px;
  height: 470px;
  background: #1c1c1c;
  border-radius: 18px;
  overflow: hidden;
}

.box::before {
  content: "";
  position: absolute;
  width: 4000px;
  height: 470px;
  top: -50%;
  left: -50%;
  background: linear-gradient(
    0deg,
    transparent,
    transparent,
    #45f3ff,
    #45f3ff,
    #45f3ff
  );
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
}

.box::after {
  content: "";
  position: absolute;
  width: 400px;
  height: 470px;
  top: -50%;
  left: -50%;
  background: linear-gradient(
    0deg,
    transparent,
    transparent,
    #ffff00,
    #ffff00,
    #ffff00
  );
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;
}

.borderLine {
  position: absolute;
  top: 0;
  inset: 0;
}

.borderLine::before {
  content: "";
  position: absolute;
  width: 400px;
  height: 470px;
  top: -50%;
  left: -50%;
  background: linear-gradient(
    0deg,
    transparent,
    transparent,
    #fc0f8d,
    #fc0f8d,
    #fc0f8d
  );
  z-index: 1;
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.box form {
  position: absolute;
  inset: 4px;
  background: #222;
  padding: 50px 40px;
  border-radius: 18px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.box form h2 {
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1em;
}
.sevaa {
  color: #fff !important;
  font-weight: 500;
  text-align: center;
  letter-spacing:3px;
}
.seva {
  color: #fff !important;
  font-weight: 400;
  text-align: center;
  letter-spacing:2px;
}

.box form .inputBox {
  position: relative;
  width: 280px;
  margin-top: 47px
}

.box form .inputBox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
}

.box form .inputBox span {
  position: absolute;
  left: 0;
  padding: 20px 0px 10px;
  pointer-events: none;
  color: #8f8f8f;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.box form .inputBox input:valid ~ span,
.box form .inputBox input:focus ~ span {
  color: #fff;
  font-size: 0.75em;
  transform: translateY(-34px);
}

.box form .inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  border-radius: 7px;
  overflow: hidden;
  transition: 0.5s;
  pointer-events: none;
}

.box form .inputBox input:valid ~ i,
.box form .inputBox input:focus ~ i {
  height: 44px;
}

.box form .links {
  display: flex;
  margin-top: 20px;
}

.box form .links lable {
  font-size: 0.40em;
  color: #8f8f8f;
  text-decoration: none;
}

.box 






.links a:hover,
.box form .links a:nth-child(2) {
  color: #fff;
}

.box form input[type="submit"] {
  border: none;
  outline: none;
  padding: 9px 25px;
  background: #fff;
  cursor: pointer;
  font-size: 0.9em;
  border-radius: 7px;
  font-weight: 600;
  width: 100px;
  margin-top: 10px;
  transition: 0.6s;
  margin: 5px;
}

.box form input[type="submit"]:hover {
  background-color: #31e1f7;
}

.box form input[type="submit"]:active {
  opacity: 0.8;
}
.image{
  height: 370px;
  width: 300px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  overflow: hidden;
  margin: 5px;
} */

/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Syne:wght@800&display=swap");

.full {
  margin: 0;
  padding: 0;
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif; */
  font-family: "Poppins", sans-serif;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  background: #a691ab;
}

.content {
  display: flex;
  flex-direction: row;
  width: 750px;
  height: 460px;
  border-radius: 10px;

  background: #fff;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #18aefa;
  text-transform: lowercase !important;
  /* background: url("https://i.postimg.cc/vmK4vkf0/neon-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.sideImg {
  width: 50%;
}

.headline {
  margin-top: 10px;
  font-size: 3em;
  font-weight: 100;
  font-family: "Bebas Neue", sans-serif;
  color: #fff;
  /* width: 10em; */
}

.right {
  margin: 20px auto;
  padding: 10px;
  margin-top: 5rem;
}

.inputbox {
  margin: 8px 0px;
  width: 100%;
  height: fit-content;
}

.inp {
  margin-top: 4px;
  padding: 5px;
  width: 100%;
  height: 50px;
  text-indent: 9px;
  border: none;
  border-radius: 2px;

  background: rgba(128, 128, 128, 0.2);
}

.inp:hover {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.right .title h6 {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 20px;
  text-wrap: wrap;
  font-weight: 900;
  color: #000;
}

.lab {
  font-size: 1em;
}

.inp::placeholder {
  text-indent: 10px;
}

.create {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.Subbtn {
  margin-top: 10px;
  width: 250px;
  height: 45px;
  border: none;
  border-radius: 25px;

  color: #fff;
  background: #000;
}

.additional p {
  margin-top: 5px;
  font-size: 11px;
  text-align: center;
}

.additional p span {
  color: blue;
}

.additional p span:hover {
  text-decoration: underline;
}

.or {
  margin-top: 10px;
  font-size: 1em;
  text-align: center;
}

.sign {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.Subbtn {
  width: 160px;
  height: 35px;
  padding: 5px;
  border-radius: 25px;
/* color: #000; */
  background: black;
  border: 2px solid #000;
}
/* Responsiveness:Start */
@media screen and (max-width: 780px) {
  .content {
    flex-direction: column;
    width: 400px;
    height: fit-content;
    margin: 20px auto;
  }
  .left {
    height: 350px;
    width: 100%;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .sideImg {
    width: 60%;
  }
}
@media screen and (max-width: 425px) {
  .content {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }
  .left {
    height: 275px;

    width: 100%;
    border-radius: 0px;
  }
  .sideImg {
    width: 35%;
  }
  .inputbox {
    /* margin: 8px 0px; */
    width: 100%;
    height: fit-content;
  }
  .right {
    margin: 20px auto;
    padding: 10px;
    width: 100%;
  }
  .create {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
/* Responsiveness:End */
