.cerficatebox {
  position: relative;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.438);
  height: 120vh;
  margin-top: 35px;
  /* background-image: url("../../assets/Maha/ E/ Seva/ Certi.jpg"); */
  background-image: url("../../assets/MahaCertificate.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.Fullname {
  position: absolute;
  top: 445px;
  left: 20rem;
}
.Fullname h3{
  color: #363435;
  font-weight: 700;
  font-family: 'Koh Santepheap', cursive;
}
.location {
  position: absolute;
  top: 578px;
  left: 31rem;
}
.location h3{
  color: #363435;
  font-weight: 700;
  font-family: 'Koh Santepheap', cursive;
  
}
.Distic {
  position: absolute;
  top: 580px;
  left: 53rem;
}
.Distic h3{
  
  color: #363435;
  font-weight: 700;
  font-family: 'Koh Santepheap', cursive;
}

.barcode {
  position: absolute;
  /* height: ; */
  /* margin: 30px; */
  width: 15rem;
  height: 15rem;
  top: 700px;
  left: 40rem;
  background-color: rgb(255, 255, 255);
  z-index: 10;
  display: flex;
  text-align: center;
  justify-content: center;
}
.barcodeBg{
  margin: 65px;
}

